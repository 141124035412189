@function em($pixels, $context) {
	@return #{$pixels/$context}em;
}

@mixin font-size($size) {
	@include rem(font-size, $size);
}

@mixin rem($property, $values) {
	$px: ();
	$rem: ();

	@each $value in $values {

		@if $value == 0 or $value == auto or $value == '0px' {
			$px: append($px, $value);
			$rem: append($rem, $value);
		}

		@else {
			$unit: unit($value);
			$val: parseInt($value);

			@if $unit == "px" {
				$px: append($px, $value);
				$rem: append($rem, ($val / $default-font-size + rem));
			}

			@if $unit == "rem" {
				$px: append($px, ($val * $default-font-size + px));
				$rem: append($rem, $value);
			}
		}
	}

	@if $px == $rem {
		#{$property}: $px;
	} @else {
		#{$property}: $px;
		#{$property}: $rem;
	}
}

@mixin font-face($weight, $family: $custom-font-family, $style: null) {
	$fontName: "#{$family}-#{$weight}";
	$fontStyle: "normal";

	@if ($style) {
		$fontName: "#{$fontName}-#{$style}";
		$fontStyle: #{$style};
	}

	@font-face {
		font-family: quote($family);
		src: url("/fonts/#{$fontName}/#{$fontName}.eot");
		src: url("/fonts/#{$fontName}/#{$fontName}.woff") format("woff");
		font-weight: #{$weight};
		font-style: #{$fontStyle};
	}
}

// Deprecated legacy! Не использовать и не удалять пока точно не будет переписано на всех проектах!
@mixin make-font-face($family, $weight, $style: null) {
	@include font-face($weight, $family, $style);
}

@mixin font($weight, $family: $custom-font-family, $default: $default-font-family) {
	font-family: $default;
	font-weight: $weight;

	.font-loaded-#{$family}-#{$weight} & {
		font-family: $family, $default;
	}
}

// Deprecated legacy! Не использовать и не удалять пока точно не будет переписано на всех проектах!
@mixin progressive-font($family, $weight, $default: $default-font-family) {
	@include font($weight, $family, $default);
}
