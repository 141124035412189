// @include font-face(400, 'Roboto');

@include font-face(300, 'Roboto');
@include font-face(400, 'Roboto');
@include font-face(500, 'Roboto');
@include font-face(700, 'Roboto');

@include font-face(400, 'FiraSansCondensed');
@include font-face(500, 'FiraSansCondensed');
@include font-face(600, 'FiraSansCondensed');
@include font-face(700, 'FiraSansCondensed');

@include font-face(300, 'FiraSansExtraCondensed');
@include font-face(400, 'FiraSansExtraCondensed');
@include font-face(500, 'FiraSansExtraCondensed');
@include font-face(600, 'FiraSansExtraCondensed');
@include font-face(700, 'FiraSansExtraCondensed');